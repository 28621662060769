import Typography from "typography"
import gray from "gray-percentage"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"

const typography = new Typography({
  title: "izayl blog theme",
  baseFontSize: "14px",
  baseLineHeight: 1.6,
  headerFontFamily: [
    "PingFang SC",
    "Helvetica Neue",
    "Helvetica,Hiragino Sans GB",
    "Microsoft YaHei",
    "Arial",
    "sans-serif",
  ],
  bodyFontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "PingFang-SC-Regular",
    "Hiragino Sans GB",
    "Microsoft Yahei",
    "Arial",
    "sans-serif",
  ],
  headerColor: "#292525",
  bodyColor: "#4a4a4a",
  bodyBackground: "#E4E5E9",
  headerWeight: "bold",
  bodyWeight: 400,
  boldWeight: 700,
  overrideStyles: ({ scale, rhythm }) => ({
    html: {
      "-webkit-font-smoothing": "antialiased",
    },
    body: {
      letterSpacing: ".03em",
    },
    a: {
      color: "#292525",
      "text-decoration": "none",
    },
    "a:hover": {
      color: "#FF69B4",
      "border-bottom-color": "#FF69B4",
    },
    blockquote: {
      ...scale(1 / 5),
      color: gray(40),
      paddingLeft: rhythm(13 / 16),
      marginLeft: rhythm(-1),
      borderLeft: `${rhythm(3 / 16)} solid ${gray(13)}`,
    },
    [MOBILE_MEDIA_QUERY]: {
      blockquote: {
        marginLeft: rhythm(-3 / 4),
        paddingLeft: rhythm(9 / 16),
        marginRight: 0,
      },
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
